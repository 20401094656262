import * as constants from '../actions/constants';

let initial = {
    data: {},
    stamps: {}
}

const quitarVariable = (byHash, name) => {
    var byHash = Object.assign({}, byHash)
    delete byHash[name]
    return byHash
}

const quitarVariables = (obj, lista) => {
    let newObj = {}
    for(let index in obj)
        if(lista.indexOf(index) < 0)
            newObj[index] = obj[index]
    return newObj;
}

const actualizarEstampa = (stamps, action) => {
    let sts = stamps ? {...stamps} : {}
    let updated = false
    if(sts[action.name]){
        if(sts[action.name] < action.timestamp){
            sts[action.name] = action.timestamp;
            updated = true;
        }
    }else{
        sts[action.name] = action.timestamp;
        updated = true;
    }
    return {stamps:sts, updated:updated}
}

const variables = (state = initial, action) => {
    // console.log(action);
    let stamped;
    let disableFields = action.disableFields ? action.disableFields : [];

    switch (action.type) {

        case constants.INICIA_ACTUALIZAR_VARIABLE:
            let dt = quitarVariable(state.data, action.name);
            return {...state, data:dt};

        case constants.ERROR_ACTUALIZAR_VARIABLE:
            stamped = actualizarEstampa(state.stamps, action)
            if(stamped.updated){
                let dt = quitarVariables(state.data, disableFields);
                return {...state, stamps:stamped.stamps, data:dt}
            }
            return {...state};

        case constants.EXITO_ACTUALIZAR_VARIABLE:
            stamped = actualizarEstampa(state.stamps, action)
            if(stamped.updated){
                let dt = quitarVariables({...state.data, [action.name]: action.value}, disableFields);
                return {...state, stamps:stamped.stamps, data:dt}
            }
            return {...state};

        default:
            return {
                ...state
            };
    }
  }
  
  export default variables;

import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as constants from '../actions/constants';
import { exitoSolicitarToken, errorSolicitarToken } from '../actions';

const getVariables = (state) => state.variables.data;
const getKeyForm = (state) => state.formulario.keyForm;
const getPayload = (state) => state.formulario.payload;
const geturlServicioTokenize = (state) => state.formulario.urlServicioTokenize;
const geturlServicioAuthorize = (state) => state.formulario.urlServicioAuthorize;
const geturlServicioAffiliation = (state) => state.formulario.urlServicioAffiliation;
const getCallback = (state) => state.formulario.callback;
const getInitPayCallback = (state) => state.formulario.initPayCallback;
const getErrorOnPayCallback = (state) => state.formulario.errorOnPayCallback;

function* solicitarToken(action) {
  const initPayCallback = yield select(getInitPayCallback);
  const errorOnPayCallback = yield select(getErrorOnPayCallback);
   try {
       initPayCallback();
       const payload = yield select(getPayload)
       const callback = yield select(getCallback)
       
       const variables = yield select(getVariables)
       const keyForm = yield select(getKeyForm);
       // console.log(payload)
       let reqData = JSON.parse(payload);
       if(reqData.action=="authorize")
        reqData.channel = "1";
       let cardData = [
                {
                    "pan": variables.v_pan ? variables.v_pan.replace(/[^0-9]+/g, '') : undefined,
                   "expiry_date": variables.v_expirydate ? variables.v_expirydate.replace(/[^0-9]+/g, '') : (variables.v_expirydate_month && variables.v_expirydate_year ? variables.v_expirydate_month+''+variables.v_expirydate_year : undefined),
                   "security_code": variables.v_cvv2 ? variables.v_cvv2.replace(/[^0-9]+/g, '') : "",//undefined,
                }
            ];
        if(reqData.action=="authorize"){
          reqData.payment_method = { "card": cardData }
        }else{
          reqData.card = cardData;
          if(reqData.action=="subscription.capture"){
            reqData.card = [{
              "payment_card_number": cardData[0].pan,
              "payment_card_expiry_date": cardData[0].expiry_date,
              "payment_security_code": cardData[0].security_code
            }]
          }
        }
        let cardHolderData = {};
        let fieldsCardHolder = ["first_name","last_name","email_address","identity_document_country","identity_document_type","identity_document_identifier"];
        let variablesCardHolder = ["v_nombre","v_apellido","v_email","identity_document_country","v_doc_type","v_dni"];
        for (let i = 0; i < fieldsCardHolder.length; i++) {
          if(variablesCardHolder[i] == "identity_document_country")
            cardHolderData[fieldsCardHolder[i]] = "PER";
          if(variables[variablesCardHolder[i]])
            cardHolderData[fieldsCardHolder[i]] = variables[variablesCardHolder[i]];
        }
        // console.log(cardHolderData);  //show
        // console.log(variables);  //show
        reqData.card_holder = reqData.card_holder ? [{...reqData.card_holder[0], ...cardHolderData }] : [{...cardHolderData}];
        if(reqData.action=="authorize"){
          if(!reqData.transaction)
            reqData.transaction = {}
          if(!reqData.transaction.meta)
            reqData.transaction.meta = {}
          if(!reqData.transaction.meta.additional_fields)
            reqData.transaction.meta.additional_fields = {}
          if(variables.v_plan)
            reqData.transaction.meta.additional_fields.plan = variables.v_plan
          if(variables.v_cuotas)
            reqData.transaction.meta.additional_fields.cuota = variables.v_cuotas
          if(!reqData.address)
            reqData.address = {}
          if(!reqData.address.billing)
            reqData.address.billing = {}
          if(!reqData.address.billing.location)
            reqData.address.billing.location = {}
          if(variables.v_country)
            reqData.address.billing.location.country = variables.v_country
          if(variables.v_state)
            reqData.address.billing.location.state = variables.v_state
          if(variables.v_city)
            reqData.address.billing.location.city = variables.v_city
          if(variables.v_zip_code)
            reqData.address.billing.location.zip_code = variables.v_zip_code
          if(variables.v_address)
            reqData.address.billing.location.line_1 = variables.v_address
          if(variables.v_confirm_tokenize || reqData.services){
            if(!reqData.services)
              reqData.services = {}
            if(!reqData.services.tokens)
              reqData.services.tokens = {};
            reqData.services.tokens.generate = variables.v_confirm_tokenize ? true : false;
          }
          if(reqData.services){
            if(reqData.services.recurrent_payment){
              console.log("-----------recurrent-as-a-service---------------")
              if(reqData.beneficiary && reqData.beneficiary instanceof Array && reqData.beneficiary.length > 0){
                let beneficiary = reqData.beneficiary[0];
                if(variables.v_beneficiary_name)
                  beneficiary.name = variables.v_beneficiary_name;
                if(variables.v_beneficiary_last_name)
                  beneficiary.last_name = variables.v_beneficiary_last_name;
                if(variables.v_beneficiary_second_last_name)
                  beneficiary.second_last_name = variables.v_beneficiary_second_last_name;
                if(variables.v_beneficiary_document_type)
                  beneficiary.identity_document_type = variables.v_beneficiary_document_type;
                if(variables.v_beneficiary_document_number)
                  beneficiary.identity_document_identifier = variables.v_beneficiary_document_number;
                if(variables.v_beneficiary_email)
                  beneficiary.email = variables.v_beneficiary_email;
                if(variables.v_beneficiary_phone){
                  if(!beneficiary.phone)
                    beneficiary.phone = {};
                  beneficiary.phone.subscriber = variables.v_beneficiary_phone;
                }
                reqData.services.recurrent_payment['beneficiary'] = reqData.beneficiary[0];
                if(reqData.services.recurrent_payment.beneficiary.last_name){
                  reqData.services.recurrent_payment.beneficiary['lastname'] = reqData.services.recurrent_payment.beneficiary.last_name;
                  delete reqData.services.recurrent_payment.beneficiary['last_name'];
                }
                if(reqData.services.recurrent_payment.beneficiary.identity_document_type){
                  reqData.services.recurrent_payment.beneficiary['document_type'] = reqData.services.recurrent_payment.beneficiary.identity_document_type;
                  delete reqData.services.recurrent_payment.beneficiary['identity_document_type'];
                }
                if(reqData.services.recurrent_payment.beneficiary.identity_document_identifier){
                  reqData.services.recurrent_payment.beneficiary['document_number'] = reqData.services.recurrent_payment.beneficiary.identity_document_identifier;
                  delete reqData.services.recurrent_payment.beneficiary['identity_document_identifier'];
                }
                
              }
              try {
                delete reqData.plan;
              } catch (error) {
                console.error(error);
              }
              try {
                delete reqData.product;
              } catch (error) {
                console.error(error);
              }
              try {
                delete reqData.subscription;
              } catch (error) {
                console.error(error);
              }
              try {
                delete reqData.beneficiary;
              } catch (error) {
                console.error(error);
              }
            }
          }
        }
        if(reqData.action=="subscription.capture"){
          console.log("--------------------------")
          if(reqData.beneficiary && reqData.beneficiary instanceof Array && reqData.beneficiary.length > 0){
            let beneficiary = reqData.beneficiary[0];
            if(variables.v_beneficiary_name)
              beneficiary.name = variables.v_beneficiary_name;
            if(variables.v_beneficiary_last_name)
              beneficiary.last_name = variables.v_beneficiary_last_name;
            if(variables.v_beneficiary_second_last_name)
              beneficiary.second_last_name = variables.v_beneficiary_second_last_name;
            if(variables.v_beneficiary_document_type)
              beneficiary.identity_document_type = variables.v_beneficiary_document_type;
            if(variables.v_beneficiary_document_number)
              beneficiary.identity_document_identifier = variables.v_beneficiary_document_number;
            if(variables.v_beneficiary_email)
              beneficiary.email = variables.v_beneficiary_email;
            if(variables.v_subscription_max_amount)
              reqData.subscription.max_amount = variables.v_subscription_max_amount;
            if(variables.v_beneficiary_phone){
              if(!beneficiary.phone)
                beneficiary.phone = {};
              beneficiary.phone.subscriber = variables.v_beneficiary_phone;
            }
            reqData['beneficiary'] = reqData.beneficiary[0];
            if(reqData.beneficiary.last_name){
              reqData.beneficiary['lastname'] = reqData.beneficiary.last_name;
              delete reqData.beneficiary['last_name'];
            }
            if(reqData.beneficiary.identity_document_type){
              reqData.beneficiary['document_type'] = reqData.beneficiary.identity_document_type;
              delete reqData.beneficiary['identity_document_type'];
            }
            if(reqData.beneficiary.identity_document_identifier){
              reqData.beneficiary['document_number'] = reqData.beneficiary.identity_document_identifier;
              delete reqData.beneficiary['identity_document_identifier'];
            }
            
          }
        }
       // let variablesToken = {
       //  "action": "authorize",
       //  "key": keyForm,
       //  "payment_method":{
       //      "card":[
       //          {
       //              "pan": variables.v_pan.replace(/[^0-9]+/g, ''),
       //             "expiry_date": variables.v_expirydate.replace(/[^0-9]+/g, ''),
       //             "security_code":variables.v_cvv2.replace(/[^0-9]+/g, ''),
       //          }
       //      ]
       //  },
       //  "card_holder":[
       //      {
       //          "first_name":variables.v_nombre,
       //          "last_name":variables.v_apellido,
       //          "identity_document_country":"PER",
       //          "identity_document_type":variables.v_doc_type,
       //          "identity_document_identifier":variables.v_dni
       //      }
       //  ],
       //  "transaction":{
       //      "currency":"604",
       //      "amount": "10300",
       //      "meta": {
       //          "internal_operation_number":"ad201997-9592-469e-97b7-8602253654b4",
       //          "description":"Descripcion de la transaccion",
       //          "additional_fields":{
       //              "1":"valor del reservado 1",
       //              "2":"valor del reservado 2",
       //              "3":"valor del reservado 3",
       //              "5":"valor del reservado 5",
       //              "6":"valor del reservado 6",
       //              "DNI":variables.v_dni
       //          }
       //      }
       //  },
       //  "address":{
       //      "shipping": {
       //          "id":"9a8bac15-84a8-4a35-a767-80e0f79d5d24"
       //      },
       //      "billing": 
       //      {
       //              "first_name":variables.v_nombre,
       //              "last_name":variables.v_apellido,
       //              "email":"foo.2010@gmail.com",
       //              "phone":{
       //                  "country_code":"51",
       //                  "subscriber":"967918120"
       //              },
       //              "location":{
       //                  "line_1":"Mi casa",
       //                  "line_2":"Mi casa",
       //                  "city":"LIMA",
       //                  "state":"LIMA",
       //                  "country":"PE",
       //                  "zip_code":"18"
       //              }
       //      }
       //  },
       //  "services": {
       //      "tokens":{ "generate":"true" }
       //  }
       // }
       const urlServicioTokenize = yield select(geturlServicioTokenize)
       const urlServicioAuthorize = yield select(geturlServicioAuthorize);
       const urlServicioAffiliation = yield select(geturlServicioAffiliation);
       console.log("urlServicioAffiliation "+urlServicioAffiliation)
       let headerAuthorize = keyForm == "GFNsyJifbvX1Yab8k4suJkMG7DBO2lGAB9F2SCWk" ? "RlbEqHnGw4zMTZNqw8lsN4po0TF6kHOa" : keyForm;

       let urlServicio = "";
       switch(reqData.action){
        case "authorize" : urlServicio = urlServicioAuthorize; break;
        case "tokenize" : urlServicio = urlServicioTokenize; break;
        case "subscription.capture" : urlServicio = urlServicioAffiliation; break;
        default : urlServicio = urlServicioTokenize; break;
       }


      const data = yield call(() => fetch( 
          // "http://172.19.44.178:4567/restapis/8368976133/local/_user_request_/token", {
          urlServicio, {
              method:"POST",
              headers:new Headers({'Authorization':headerAuthorize, 'content-type':'application/json', 'Accept':'application/json'}),
              body: JSON.stringify(reqData)
               // mode: 'no-cors',
          }
        ).then(res => {
            return res.json()
        }))
        if(data.error){
            errorOnPayCallback();
            throw new Error(data.error);
        }
      yield put(exitoSolicitarToken([data]))
      callback(data);
   } catch (error) {
       // console.log(error);  //show
       errorOnPayCallback();
      yield put(errorSolicitarToken());
   }
}

export default function* cargarFormularioSaga() {
   yield takeLatest(constants.INICIA_SOLICITAR_TOKEN, solicitarToken)
 }
import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as constants from '../actions/constants';
import { exitoAutorizarConToken, errorAutorizarConToken } from '../actions';

const getPayload = (state) => state.tokens.payload;
const getToken = (state) => state.tokens.autorizacion.token;
const getKeyForm = (state) => state.tokens.keyForm;
const geturlServicioAuthorize = (state) => state.tokens.urlServicioAuthorize;
const getCallback = (state) => state.tokens.callback;

function* autorizarConToken(action) {
   try {
       const payload = yield select(getPayload)
       const callback = yield select(getCallback)
       const token = yield select(getToken)
       const keyForm = yield select(getKeyForm);
       let reqData = JSON.parse(payload);
       reqData.channel = "1";
       reqData.payment_method = {
            "token":[
                    {
                        "id":token.id,
                    }
                ]
        };
       //  reqData.card_holder = [
       //      {
       //          "first_name":variables.v_nombre,
       //          "last_name":variables.v_apellido,
       //          "email_address":"foo.bar@email.com",
       //          "identity_document_country":"PER",
       //          "identity_document_type":variables.v_doc_type,
       //          "identity_document_identifier":variables.v_dni
       //      }
       //  ];
       // let variablesAuthorize = {
       //      "action": "authorize",
       //      "payment_method":{
       //          "token":[
       //              {
       //                  "id":token.id,
       //              }
       //          ]
       //      },
       //      "transaction":{
       //          "currency":"604",
       //          "amount": "10300",
       //          "meta": {
       //              "internal_operation_number":"ad201997-9592-469e-97b7-8602253654b4",
       //              "description":"Descripcion de la transaccion",
       //              "additional_fields":{
       //                  "1":"valor del reservado 1",
       //                  "2":"valor del reservado 2",
       //                  "3":"valor del reservado 3",
       //                  "5":"valor del reservado 5",
       //                  "6":"valor del reservado 6",
       //                  "DNI":"valor del reservado que contiene el dato de DNI"
       //              }
       //          }
       //      },
       //      "address":{
       //          "shipping": {
       //              "id":"9a8bac15-84a8-4a35-a767-80e0f79d5d24"
       //          },
       //          "billing": 
       //          {
       //                  "first_name":"Juan Diego",
       //                  "last_name":"Camino",
       //                  "email":"epariasca.2010@gmail.com",
       //                  "phone":{
       //                      "country_code":"51",
       //                      "subscriber":"967918120"
       //                  },
       //                  "location":{
       //                      "line_1":"Mi casa",
       //                      "line_2":"Mi casa",
       //                      "city":"LIMA",
       //                      "state":"LIMA",
       //                      "country":"PE",
       //                      "zip_code":"18"
       //                  }
       //          }
       //      }
       //  }

        const urlServicioAuthorize = yield select(geturlServicioAuthorize)
        const data = yield call(() => fetch(
            urlServicioAuthorize, {
                method:"POST",
                headers:{'Authorization':keyForm, 'Content-Type':'application/json', 'Accept':'application/json'},
                body: JSON.stringify(reqData)
          }).then(res => {
              return res.json()
          }))
          if(data.error)
              throw new Error(data.error)
        yield put(exitoAutorizarConToken(data))
        callback(data);
   } catch (error) {
       //console.log(error); //show
      yield put(errorAutorizarConToken())
   }
}

export default function* cargarFormularioSaga() {
   yield takeLatest(constants.INICIA_AUTORIZAR_CON_TOKEN, autorizarConToken)
 }
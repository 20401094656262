import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as constants from '../actions/constants';
import { iniciaActualizarVariable, exitoActualizarVariable, errorActualizarVariable } from '../actions';

const getIdFormulario = (state) => state.formulario.id;
const getPayload = (state) => state.formulario.payload;
const getFieldsFormulario = (state) => state.formulario.formulario.fields;
const getVariables = (state) => ({...state.variables.data});
const getURLServicioFormulario = (state) => state.formulario.urlServicioFormulario;
const getKeyForm = (state) => state.formulario.keyForm;

function* validarData(action) {
    const payload = yield select(getPayload)
    let reqData = JSON.parse(payload);
    let name = action.name;
    let dataToSend = {...action}
    let varsDep = yield select(getVariables)
    dataToSend.timestamp = Date.now();
    dataToSend.service = reqData.action;
    if(reqData.transaction){
      dataToSend.transaction = {}
      if(reqData.transaction.currency)
        dataToSend.transaction.currency = reqData.transaction.currency
    }
    dataToSend.dependentsValues = {};
    if(!action.dependsOn){
      dataToSend.dependsOn = [];
    }else{
      for (let i = 0; i < dataToSend.dependsOn.length; i++) {
        // console.log(dataToSend.dependsOn);
        if(!varsDep[dataToSend.dependsOn[i]])
          continue;
        else
          dataToSend.dependentsValues[dataToSend.dependsOn[i]] = varsDep[dataToSend.dependsOn[i]];
      }
    }
    let actualizo = false;
   try {
       const idFormulario = yield select(getIdFormulario)
       const urlServicioFormulario = yield select(getURLServicioFormulario)
       const keyForm = yield select(getKeyForm);
       // console.log(urlServicioFormulario)
      const data = yield call((id) => fetch(
          urlServicioFormulario+"/"+idFormulario+"/validar", {
              method:"POST",
              headers:{'Authorization':keyForm, 'Content-Type':'application/json', 'Accept':'application/json'},
              body: JSON.stringify(dataToSend)
        }).then(res => {
            return res.json()
        }), action.id)
        let {error, value, additionalFields, enableFields, disableFields, timestamp} = data;
        if(error)
            throw new Error(JSON.stringify({error:error, additionalFields:additionalFields, enableFields:enableFields, disableFields:disableFields, timestamp:timestamp}))
      // console.log(enableFields)
      // console.log("exito")
      yield put(exitoActualizarVariable(name, value, additionalFields, enableFields, disableFields, timestamp))
      actualizo = true

   } catch (error) {
      // console.log("error")
      // console.log(error)
       let errorData;
       try {
            errorData = JSON.parse(error.message);
        } catch(e) {
            errorData = {error:error.message}
        }
      // console.log(errorData)
      yield put(errorActualizarVariable(name, errorData.error, errorData.additionalFields, errorData.enableFields, errorData.disableFields, errorData.timestamp))
   }
   if(actualizo){
    let fields = yield select(getFieldsFormulario)
    for (let i = 0; i < fields.length; i++) {
     
      if(fields[i].dependsOn){
        if(fields[i].dependsOn.indexOf(action.name) >= 0 && fields[i].status){
          if(fields[i].name == "v_cuotas"){
            if(action.name=="v_plan" && action.type=="INICIA_ACTUALIZAR_VARIABLE" && fields[i].options){
              if(fields[i].options.length > 1){
                fields[i].value = fields[i].options[1].value
                yield put(iniciaActualizarVariable(fields[i].name, fields[i].value, fields[i].dependsOn, true))
                continue;
              }
            }else if(fields[i].value && fields[i].value != "0" && fields[i].options){
              if(!fields[i].options.find((ele) => fields[i].value == ele.value))
                fields[i].value = "0"
                yield put(iniciaActualizarVariable(fields[i].name, fields[i].value, fields[i].dependsOn, true))
                continue;
            }
          }
          yield put(iniciaActualizarVariable(fields[i].name, fields[i].value, fields[i].dependsOn, action.updateVisual))
        }else if(fields[i].dependsOn.indexOf(action.name) >= 0 && fields[i].name == "v_cuotas"){
          if(action.name=="v_plan" && action.type=="INICIA_ACTUALIZAR_VARIABLE" && fields[i].options){
            if(fields[i].options.length > 1){
              fields[i].value = fields[i].options[1].value
              yield put(iniciaActualizarVariable(fields[i].name, fields[i].value, fields[i].dependsOn, true))
              continue;
            }
          }
        }
      }
      if(fields[i].name == "v_plan"){
        if(action.name=="v_pan" && action.type=="INICIA_ACTUALIZAR_VARIABLE" && fields[i].options){
          if(fields[i].options.length > 1){
            yield put(iniciaActualizarVariable(fields[i].name, fields[i].options[1].value, fields[i].dependsOn, true))
            continue;
          }
        }
      }
    }
   }
}

export default function* cargarFormularioSaga() {
   yield takeEvery(constants.INICIA_ACTUALIZAR_VARIABLE, validarData)
 }
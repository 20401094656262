import React from 'react'
import { connect } from 'react-redux'
import * as constants from '../actions/constants';
import { iniciaActualizarVariable, iniciaSolicitarToken } from '../actions';
import Field from '../components/Field'
import PropTypes from 'prop-types'
  
const mapStateToProps = state => {
    let formState = state.formulario;
    let tokensState = state.tokens;
    // console.log(formState);
    switch(formState.status){
        case constants.INICIA_CARGAR_FORMULARIO:
            return {
                title: formState.formulario.title,
                bgColor: formState.formulario.bgColor,
                status: formState.status,
                fields: [],
                valid: formState.formulario.valid,
                variables: state.variables.data,
                tokenizado: false
            }
        case constants.EXITO_CARGAR_FORMULARIO:
            return {
                title: formState.formulario.title,
                bgColor: formState.formulario.bgColor,
                status: formState.status,
                fields: formState.formulario.fields,
                valid: formState.formulario.valid,
                variables: state.variables.data,
                tokenizado: false
            }
        case constants.ERROR_CARGAR_FORMULARIO:
            return {    
                title: formState.error.message || formState.error,
                bgColor: "#ff0000",
                status: formState.status,
                fields: [],
                valid: formState.formulario.valid,
                variables: state.variables.data,
                tokenizado: false
            }

        case constants.INICIA_SOLICITAR_TOKEN:
            return {    
                title: formState.formulario.title,
                bgColor: formState.formulario.bgColor,
                status: formState.status,
                fields: formState.formulario.fields,
                valid: formState.formulario.valid,
                variables: state.variables.data,
                tokenizado: false,
                enviando: true
            }

        case constants.EXITO_SOLICITAR_TOKEN:
            return {    
                title: formState.formulario.title,
                bgColor: formState.formulario.bgColor,
                status: formState.status,
                fields: formState.formulario.fields,
                valid: formState.formulario.valid,
                variables: state.variables.data,
                tokenizado: true,
                tokens: tokensState.tokens[0],
                enviando: false
            }

        case constants.ERROR_SOLICITAR_TOKEN:
            return {    
                title: formState.formulario.title,
                bgColor: formState.formulario.bgColor,
                status: formState.status,
                fields: formState.formulario.fields,
                valid: formState.formulario.valid,
                variables: state.variables.data,
                tokenizado: false,
                enviando: false
            }

        default:
            return {
                title: "-",
                bgColor: "",
                status: "-",
                fields: [],
                valid: false,
                variables: state.variables.data
            }
    }
}

const mapDispatchToProps = dispatch => ({
    onChange: (name, value, dependsOn) => {
        dispatch(iniciaActualizarVariable(name, value, dependsOn))
    },
    onClickEnviar: () => dispatch(iniciaSolicitarToken())
  })

const Formulario = ({ title, bgColor, status, fields, variables, onChange, onClickEnviar, valid, enviando, tokenizado, tokens }) => (
    <div className={"form-wrapper"} style={{backgroundColor:bgColor}}>          
          <h2>{title}</h2>
          {!tokenizado &&
            <form>
                {fields.map((value,pos) => (<Field key={pos.toString()} onChange={onChange} enviando={enviando} {...value} />))}
                <div className="submit"><button type="button" disabled={!valid || enviando} onClick={onClickEnviar}>Enviar {valid}</button></div>
                <pre>{JSON.stringify(variables, null, ' ')}</pre>
            </form>
          }
          {tokenizado && 
            <div className={"response"}>
                <h3>Resultado </h3>
                <pre>{JSON.stringify(tokens, null, ' ')}</pre>
            </div>
          }
    </div>
)

Formulario.propTypes = {
    title: PropTypes.string,
    bgColor: PropTypes.string,
    status: PropTypes.string.isRequired
}

export default connect( mapStateToProps, mapDispatchToProps )(Formulario)

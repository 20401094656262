import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware  } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducers from './reducers'
import cargarFormulario from './sagas/cargarFormulario'
import validarCampo from './sagas/validarCampo'
import validarFormulario from './sagas/validarFormulario'
import enviarFormulario from './sagas/enviarFormulario'
import autorizarConToken from './sagas/autorizarConToken'
import App from './components/App';
import { Provider } from 'react-redux';
import { iniciaCargarFormulario, iniciaListarToken } from './actions'

class Formulario extends Component {

  constructor(props){
    super(props);
    this.state = {};
    let sagaMiddleware = createSagaMiddleware()
    this.store = createStore(rootReducers, applyMiddleware(sagaMiddleware))
    sagaMiddleware.run(cargarFormulario)
    sagaMiddleware.run(validarCampo)
    sagaMiddleware.run(validarFormulario)
    sagaMiddleware.run(enviarFormulario)
    sagaMiddleware.run(autorizarConToken  )
    if(props.mostrarTokens){
      this.store.dispatch(iniciaListarToken(
        props.keyForm,
        props.tokens,
        props.urlServicioAuthorize,
        props.payload,
        props.callback,
        ))
    }else{
      this.store.dispatch(iniciaCargarFormulario(
        props.idFormulario,
        props.keyForm,
        props.urlServicioFormulario,
        props.urlServicioTokenize,
        props.urlServicioAuthorize,
        props.urlServicioAffiliation,
        props.callback,
        props.additionalFields,
        props.payload,
        props.initPayCallback,
        props.errorOnPayCallback
        ))
    }
      
  }
  
  render() {
    return (
      <Provider store={this.store}>
        <App />
      </Provider>
    )
  }
}

class FlexCapture{


  constructor(config){
    let key;
    // console.log("123456789")
    // if(!config.urlServicioFormulario)
      config.urlServicioFormulario = __URL_SERVICIO_FORMULARIO__
    // if(!config.urlServicioTokenize)
      config.urlServicioTokenize = __URL_SERVICIO_TOKENS__
    // if(!config.urlServicioCharges)
      config.urlServicioCharges = __URL_SERVICIO_CHARGES__
      config.urlServicioAffiliation = __URL_SERVICIO_AFFILIATION__
      console.log("==========+++++++"+config.urlServicioAffiliation)
    // if(!config.urlServicioAuthorize)
    //   config.urlServicioAuthorize = __URL_SERVICIO_CHARGES__
    if(!config.callback){
      config.callback = ()=>{};
    }
    if(!config.additionalFields){
      config.additionalFields = [];
    }
    if(!config.key)
      throw new Error("Key no configurada")
    key = config.key;
    let payload = JSON.parse(JSON.stringify(config.payload));
    try {
      payload.plan = payload.services.recurrent_payment.plan;
    } catch (error) {
      // console.error(error);
      console.log("no hay services.recurrent_payment.plan, se toma el de payload")
    }
    try {
      payload.plan = payload.services.recurrent_payment.product;
    } catch (error) {
      // console.error(error);
      console.log("no hay services.recurrent_payment.product, se toma el de payload")
    }
    try {
      payload.subscription = payload.services.recurrent_payment.subscription;
    } catch (error) {
      // console.error(error);
      console.log("no hay services.recurrent_payment.subscription, se toma el de payload")
    }
    try {
      payload.beneficiary = payload.services.recurrent_payment.beneficiary;
    } catch (error) {
      // console.error(error);
      console.log("no hay services.recurrent_payment.beneficiary, se toma el de payload")
    }
    this.config = {
      idFormulario: 4, // hardcodeado, por el momento no borrar
      keyForm:key, 
      urlServicioFormulario:config.urlServicioFormulario, 
      urlServicioTokenize:config.urlServicioTokenize,
      urlServicioAuthorize:config.urlServicioCharges,
      urlServicioAffiliation:config.urlServicioAffiliation,
      additionalFields:config.additionalFields,
      payload:JSON.stringify(payload)
    }

    // this.app = React.createElement(Formulario, configForm);

    // ReactDOM.render(this.app, domElement)
    var self = this;
    this.init = function(){
      console.log(arguments);
      let domElement = arguments[0];
      let callback = arguments[1];
      let initPayCallback = ()=>{};
      let errorOnPayCallback = ()=>{};
      if(arguments.length>2){
        initPayCallback = arguments[2];
      }
      if(arguments.length>3){
        errorOnPayCallback = arguments[3];
      }
      ReactDOM.render(React.createElement(Formulario, {...self.config, callback:callback, initPayCallback:initPayCallback, errorOnPayCallback:errorOnPayCallback}), domElement);
    }

    this.initUsetoken = (domElement, tokens, callback) => {
      ReactDOM.render(React.createElement(Formulario, {...this.config, callback:callback, mostrarTokens:true, tokens:tokens}), domElement)
    }
  }


}

export default FlexCapture

import * as constants from './constants';

export const iniciaCargarFormulario = (id, keyForm, urlServicioFormulario, urlServicioTokenize, urlServicioAuthorize, urlServicioAffiliation, callback, additionalFields, payload, initPayCallback, errorOnPayCallback) => ({
        type: constants.INICIA_CARGAR_FORMULARIO,
        id: id,
        keyForm: keyForm,
        urlServicioFormulario: urlServicioFormulario,
        urlServicioTokenize: urlServicioTokenize,
        urlServicioAuthorize: urlServicioAuthorize,
        urlServicioAffiliation: urlServicioAffiliation,
        callback: callback,
        additionalFields: additionalFields,
        payload: payload,
        initPayCallback:initPayCallback,
        errorOnPayCallback:errorOnPayCallback
    })
export const exitoCargarFormulario = formulario => ({
        type: constants.EXITO_CARGAR_FORMULARIO,
        formulario: formulario
    })
export const errorCargarFormulario = error => ({
        type: constants.ERROR_CARGAR_FORMULARIO,
        error: error
    })

export const iniciaActualizarVariable = (name, value, dependsOn, updateVisual=false) => ({
        type: constants.INICIA_ACTUALIZAR_VARIABLE,
        name: name,
        value: value,
        dependsOn: dependsOn,
        updateVisual: updateVisual
    })
export const exitoActualizarVariable = (name, value, additionalFields, enableFields, disableFields, timestamp) => ({
        type: constants.EXITO_ACTUALIZAR_VARIABLE,
        name: name,
        value: value,
        additionalFields: additionalFields,
        enableFields: enableFields,
        disableFields: disableFields,
        timestamp: timestamp
    })
export const errorActualizarVariable = (name, error, additionalFields, enableFields, disableFields, timestamp) => ({
        type: constants.ERROR_ACTUALIZAR_VARIABLE,
        name: name,
        error: error,
        additionalFields: additionalFields,
        enableFields: enableFields,
        disableFields: disableFields,
        timestamp: timestamp
    })
export const validacionCorrectaFormulario = () => ({
        type: constants.VALIDACION_CORRECTA_FORMULARIO
    })
export const validacionFallidaFormulario = () => ({
        type: constants.VALIDACION_FALLIDA_FORMULARIO
    })

export const iniciaSolicitarToken = () => ({
        type: constants.INICIA_SOLICITAR_TOKEN
    })
export const exitoSolicitarToken = (tokens) => ({
        type: constants.EXITO_SOLICITAR_TOKEN,
        tokens: tokens
    })
export const errorSolicitarToken = () => ({
        type: constants.ERROR_SOLICITAR_TOKEN
    })
export const iniciaListarToken = (keyForm, tokens, urlServicioAuthorize, payload, callback) => ({
        type: constants.INICIA_LISTAR_TOKENS,
        keyForm: keyForm,
        tokens: tokens,
        urlServicioAuthorize: urlServicioAuthorize,
        payload: payload,
        callback: callback
    })
export const elegirToken = (token) => ({
        type: constants.ELEGIR_TOKEN,
        token: token
    })
export const iniciaAutorizarConToken = () => ({
        type: constants.INICIA_AUTORIZAR_CON_TOKEN
    })
export const exitoAutorizarConToken = (response) => ({
        type: constants.EXITO_AUTORIZAR_CON_TOKEN,
        response: response
    })
export const errorAutorizarConToken = () => ({
        type: constants.ERROR_AUTORIZAR_CON_TOKEN
    })
import React from 'react'
import { connect } from 'react-redux'
import * as constants from '../actions/constants';
import { elegirToken, iniciaAutorizarConToken } from '../actions';
import Field from '../components/Field'
import PropTypes from 'prop-types'
  
const mapStateToProps = state => {
    let tokensState = state.tokens;
    let autorizacionState = state.tokens.autorizacion;
    // console.log(autorizacionState);
    switch(autorizacionState.status){
        case constants.INICIA_AUTORIZAR_CON_TOKEN:
            return {
                tokenUsado: false,
                tokenElegido: tokensState.tokenElegido,
                tokens: tokensState.tokens
            }
        case constants.EXITO_AUTORIZAR_CON_TOKEN:
            return {
                tokenUsado: true,
                tokenElegido: tokensState.tokenElegido,
                tokens: tokensState.tokens,
                respuesta: tokensState.autorizacion.response
            }
        case constants.ERROR_AUTORIZAR_CON_TOKEN:
            return {    
                tokenUsado: false,
                tokenElegido: tokensState.tokenElegido,
                tokens: tokensState.tokens
            }

        default:
            return {
                tokenUsado: false,
                tokenElegido: tokensState.tokenElegido,
                tokens: tokensState.tokens
            }
    }
}
const mapDispatchToProps = dispatch => ({
    onChange: (tokens, id) => {dispatch(elegirToken(tokens.find((token)=>token.id==id)))},
    onClickAutorizar: () => {dispatch(iniciaAutorizarConToken())}
  })

const TokensList = ({ tokens, onChange, onClickAutorizar, tokenElegido, tokenUsado, respuesta }) => (
    <div>          
          <h2>Usar Token</h2>
            {!tokenUsado &&
                <div>
                    <div>
                          {tokens.map((value,pos) => (
                            <p key={pos.toString()} >
                                <label><input type="radio" name="tokens" onChange={(event) => onChange(tokens, event.target.value) } value={value.id}/>{value.label}</label><br />
                            </p>))}
                    </div>
                    <button type="button" disabled={!tokenElegido} onClick={onClickAutorizar}>Enviar</button>
                </div>
            }
            {tokenUsado &&
                <div>
                    <h3>Respuesta</h3>
                    <pre>{JSON.stringify(respuesta, null, ' ')}</pre>
                </div>
            }
    </div>
)

export default connect( mapStateToProps, mapDispatchToProps )(TokensList)

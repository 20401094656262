import React, {Component} from 'react'
import { connect } from 'react-redux'

import '../css/marcas.css';

// hack para agregar la clase has-value
const mapStateToProps = (state, ownProps) => {
    let formState = state.formulario.formulario;
    let field = formState.fields.find((element)=>{return (ownProps.name==element.name)})
    let hasValue = false;
    if(field){
        return {hasValue: (field.type == "input" && field.value && field.value != "") || (field.type == "number" && field.value) ||  (field.type == "select" && field.value && field.value != "" && field.value != "0")}
    }
    return { hasValue: hasValue, updateVisual:field.updateVisual }
}

class Field extends Component {

    constructor(props){
        super(props);
        this.state = {focus: false, value:""};
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.charRestriction = null;
        this.maxLength = null;
        this.maskDate = null;
        this.maskPan = null;
        if(this.props.input_config){
            if(this.props.input_config.char_restriction)
                this.charRestriction = new RegExp(this.props.input_config.char_restriction, 'g');
            if(this.props.input_config.max_length)
                this.maxLength = this.props.input_config.max_length;
            if(this.props.input_config.mask_date)
                this.maskDate = this.props.input_config.mask_date;
            if(this.props.input_config.mask_pan)
                this.maskPan = this.props.input_config.mask_pan;
        }
    }

    onFocus(){
        this.setState({focus: true})
        if(this.maskPan && this.props.hasValue && !this.props.error){
            this.setState({value: this.props.value});
        }
    }

    onBlur(){
        this.setState({focus: false})
        if(this.maskPan && this.props.hasValue && !this.props.error){
            this.setState({value: "***"+this.props.value.substr(this.props.value.length-4)});            
        }
    }

    onChangeCheckbox(event){     
        let value =  event.target.checked;
        this.setState({value: value})
        this.props.onChange(this.props.name, value, this.props.dependsOn)
    }

    onChangeSelect(event){
        let value = event.target.value;
        this.setState({value: value})
        this.props.onChange(this.props.name, value, this.props.dependsOn)
    }

    onChangeInput(event){
        let value = event.target.value;
        if(this.props.name=="v_subscription_max_amount"){
            let numberValue = new Number(value);
            numberValue = numberValue*100000;
            value = numberValue;
            console.log({value})
        }
        let valueOri = this.state.value;
        if(this.charRestriction && value){
            let nums = value.match(this.charRestriction);
            value = nums ?  nums.join('') : '';
        }
        if(this.maxLength && value){
            value = value.substr(0,this.maxLength);
        }
        let maskedValue = value;
        if(this.maskDate && value){
            if(value.length > 2)
                maskedValue = value.substr(0,2)+"/"+ value.substr(2);
            value = maskedValue;
        }
        this.setState({value: maskedValue})
        if(valueOri != value)
            this.props.onChange(this.props.name, value, this.props.dependsOn) 
    }
  
    render() {

    let { name, additionalFields, type, placeholder, options, label, error, enabled, onChange, dependsOn, hasValue, input_config, updateVisual, enviando } = this.props;
    let claseFocus = this.state.focus ? " has-focus" : "" ;
    let claseInvalid = error ? " invalid" : "" ;
    let claseHasValue = hasValue ? " has-value" : "" ;
    let claseValid = hasValue && !error ? " valid" : "";
    let claseMarca = "marca";
    if(additionalFields){
        if(additionalFields.marca)
            claseMarca += " "+additionalFields.marca
    }        
    let inputValue = updateVisual ? this.props.value : this.state.value;
    if(type=='static_amount'){
        if(!isNaN(inputValue)){
            let numberValue = new Number(inputValue);
            numberValue = (numberValue/100000).toFixed(2);
            inputValue = numberValue;
            console.log({inputValue})
        }
    }
    let step,min_value;
    if(name=="v_subscription_max_amount"){
        type="numeric_input";
        step="0.01";
        min_value="0";
        let numberValue = new Number(inputValue);
        numberValue = (numberValue/100000).toFixed(2);
        inputValue = numberValue;
    }
    let input;
    let input_type = input_config ? (input_config.type ? input_config.type : type ) : type;
    let input_mode = input_config ? (input_config.inputmode ? input_config.inputmode : null ) : null;
    let input_style = {};
    if(input_type=="password"){
        input_type="text";
        input_style={...input_style, WebkitTextSecurity:"disc", MozTextSecurity:"disc", textSecurity:"disc"};
    }
    let isCheckbox = input_type == "checkbox";
    if(!enabled)
        return "";
    switch(type){
        case "input":
            if(isCheckbox)
                return (<div className={"field field-"+name+claseHasValue+claseInvalid+claseValid+claseFocus}>       
                            <input id={name} type={input_type} onBlur={this.onBlur} onFocus={this.onFocus} checked={inputValue} onChange={this.onChangeCheckbox} disabled={enviando}/>    
                            <label htmlFor={name}>{label}</label>               
                            <small className="error-message">{error}</small>
                        </div>);
            return (<div className={"field field-"+name+claseHasValue+claseInvalid+claseValid+claseFocus}>
                <label>{label}</label>
                <input type={input_type} style={input_style} value={inputValue} inputMode={input_mode} onBlur={this.onBlur} onFocus={this.onFocus} onChange={()=>{}} onInput={this.onChangeInput} placeholder={placeholder} disabled={enviando}/>
                {name == "v_pan" &&
                <span className={claseMarca}></span>}
                <small className="error-message">{error}</small>
            </div>);
        case "numeric_input":
            return (<div className={"field field-"+name+claseHasValue+claseInvalid+claseValid+claseFocus}>
                <label>{label}</label>
                <input type={input_type} min={min_value} step={step} value={inputValue} inputMode={input_mode} onBlur={this.onBlur} onFocus={this.onFocus} onChange={()=>{}} onInput={this.onChangeInput} placeholder={placeholder}  disabled={enviando}/>
                <small className="error-message">{error}</small>
            </div>);
         case "select":
            return (<div className={"field field-"+name+claseHasValue+claseInvalid+claseValid+claseFocus}>
                <label>{label}</label>
                <select value={inputValue} onBlur={this.onBlur} onFocus={this.onFocus} onChange={this.onChangeSelect}  disabled={enviando}>
                    {options.map((element, pos)=><option key={pos.toString()} value={element.value}>{element.label}</option>)}
                </select>
                <small className="error-message">{error}</small>
            </div>);
        case "input_token":
            return (<p>
                <label><input type="radio" ref={node => input = node} onChange={(event) => onChange(event.target.value) } />{label}</label><br />
            </p>);
        case "static":
            return (<div className={"field field-"+name+claseHasValue+claseInvalid+claseValid+claseFocus}>
                <label>{label}</label>
                <span>{inputValue}</span>
                <small className="error-message">{error}</small>
            </div>);
        case "static_date":
            let date_obj = new Date(1000 *inputValue);
            let date_str = date_obj.toISOString().slice(0,10);
            return (<div className={"field field-"+name+claseHasValue+claseInvalid+claseValid+claseFocus}>
                <label>{label}</label>
                <span>{date_str}</span>
                <small className="error-message">{error}</small>
            </div>);
        case "static_amount":
            return (<div className={"field field-"+name+claseHasValue+claseInvalid+claseValid+claseFocus}>
                <label>{label}</label>
                <span>S/ {inputValue}</span>
                <small className="error-message">{error}</small>
            </div>);
        default:
            return (<p>{label}</p>)
    }

    }
}

export default connect( mapStateToProps )(Field)
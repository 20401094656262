import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as constants from '../actions/constants';
import { exitoCargarFormulario, errorCargarFormulario, iniciaActualizarVariable } from '../actions';

const getAdditionalFields = (state) => state.formulario.additionalFields;
const getFieldsFormulario = (state) => state.formulario.formulario.fields;
const getKeyForm = (state) => state.formulario.keyForm;
const getPayload = (state) => state.formulario.payload;

function* getData(action) {
  const keyForm = yield select(getKeyForm);
   try {
   		const additionalFields = yield select(getAdditionalFields)
      const data = yield call((id) => fetch(action.urlServicioFormulario+"/"+id,
      	{
              method:"POST",
              headers:{'Authorization':keyForm, 'Content-Type':'application/json', 'Accept':'application/json'},
              body: JSON.stringify({additionalFields:additionalFields})
        }
      	).then(res => {return res.json()}), action.id)
      yield put(exitoCargarFormulario(data))
   } catch (error) {
      yield put(errorCargarFormulario(error))
   }
}

function* setInicialData(action) {  
  const payloadString = yield select(getPayload)
  const fields = yield select(getFieldsFormulario);
  let payload = JSON.parse(payloadString);
  if(payload.card_holder){
    if(payload.card_holder.length){
      let cardHolderData = payload.card_holder[0];
      let fieldsCardHolder = ["first_name","last_name","email_address","identity_document_country","identity_document_type","identity_document_identifier"];
      let variablesCardHolder = ["v_nombre","v_apellido","v_email","identity_document_country","v_doc_type","v_dni"];
      for (let i = 0; i < fieldsCardHolder.length; i++) {
        if(cardHolderData[fieldsCardHolder[i]]){
          let name = variablesCardHolder[i];
          let field = fields.find((element)=>{return (name==element.name)})
          if(field){
            yield put(iniciaActualizarVariable(name, cardHolderData[fieldsCardHolder[i]], field.dependsOn, true));
          }
        }
      }
    }
  }
  if(payload.beneficiary && payload.beneficiary instanceof Array){
    console.log("==============")
    console.log(payload.beneficiary)
    let subscriptionData = payload.beneficiary[0];
    let fieldsSubscription = ["name","last_name","second_last_name","email","identity_document_type","identity_document_identifier"];
    let variablesSubscription = ["v_beneficiary_name","v_beneficiary_last_name","v_beneficiary_second_last_name","v_beneficiary_email","v_beneficiary_document_type","v_beneficiary_document_number"];
    for (let i = 0; i < fieldsSubscription.length; i++) {
      console.log("========="+fieldsSubscription[i]+"============"+subscriptionData[fieldsSubscription[i]])
      if(subscriptionData[fieldsSubscription[i]]){
        console.log("==========="+fieldsSubscription[i])
        let name = variablesSubscription[i];
        let field = fields.find((element)=>{return (name==element.name)})
        if(field){
          yield put(iniciaActualizarVariable(name, subscriptionData[fieldsSubscription[i]], field.dependsOn, true));
        }
      }
    }
    if(subscriptionData.phone){
      if(subscriptionData.phone.subscriber){
        let field = fields.find((element)=>{return ("v_beneficiary_phone"==element.name)})
        yield put(iniciaActualizarVariable("v_beneficiary_phone", subscriptionData.phone.subscriber, field.dependsOn, true));
      }
    }
  }
  if(payload.subscription){
    let subscriptionData = payload.subscription;
    let fieldsSubscription = ["amount","max_amount","first_payment_date","type"];
    let variablesSubscription = ["v_subscription_amount","v_subscription_max_amount","v_subscription_first_payment_date","v_subscription_type"];
    for (let i = 0; i < fieldsSubscription.length; i++) {
      if(subscriptionData[fieldsSubscription[i]]){
        let name = variablesSubscription[i];
        let field = fields.find((element)=>{return (name==element.name)})
        if(field){
          yield put(iniciaActualizarVariable(name, subscriptionData[fieldsSubscription[i]], field.dependsOn, true));
        }
      }
    }
  }
  let field = fields.find((element)=>{return ("v_confirm_tokenize"==element.name)})
  if(field){
    let generate = false;
    if(payload.services)
      if(payload.services.tokens)        
        if(payload.services.tokens.generate)
          generate = String(payload.services.tokens.generate).toLowerCase() == "true";
    yield put(iniciaActualizarVariable("v_confirm_tokenize", generate, field.dependsOn, true));
  }
    
}

export default function* cargarFormularioSaga() {
   yield takeEvery(constants.INICIA_CARGAR_FORMULARIO, getData)
   yield takeEvery(constants.EXITO_CARGAR_FORMULARIO, setInicialData)
 }
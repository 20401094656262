import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as constants from '../actions/constants';
import { validacionCorrectaFormulario, validacionFallidaFormulario } from '../actions';

const getFormulario = (state) => state.formulario.formulario;

function* verificarCampos(action) {
	const formulario = yield select(getFormulario)
	let is_valid = true;
   try {
   	for (var i = 0; i < formulario.fields.length; i++) {
   		if(formulario.fields[i].enabled && formulario.fields[i].status !== constants.EXITO_ACTUALIZAR_VARIABLE){
   			is_valid = false;
   			break;
   		}
   	}
   	if(is_valid)
   		yield put(validacionCorrectaFormulario())
   	else
   		yield put(validacionFallidaFormulario())
   } catch (error) {
      yield put(validacionFallidaFormulario())
   }
}

export default function* cargarFormularioSaga() {
   yield takeLatest(constants.EXITO_ACTUALIZAR_VARIABLE, verificarCampos)
   yield takeLatest(constants.INICIA_ACTUALIZAR_VARIABLE, verificarCampos)
   yield takeLatest(constants.ERROR_ACTUALIZAR_VARIABLE, verificarCampos)
 }
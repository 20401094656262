import React from 'react';
import Formulario from '../containers/Formulario';
import TokensList from '../containers/TokensList';
import { connect } from 'react-redux'
import * as constants from '../actions/constants';
import { iniciaActualizarVariable, iniciaSolicitarToken } from '../actions';
  
const mapStateToProps = state => {
    let tokensState = state.tokens;
    // console.log(formState);
    switch(tokensState.status){
        case constants.INICIA_LISTAR_TOKENS:
            return {
                mostrarTokens: true,
                mostrarFormulario: false,
            }

        default:
            return {
                mostrarTokens: false,
                mostrarFormulario: true,
            }
    }
}


const App = ({mostrarTokens, mostrarFormulario}) => (
    <div className={"flex-capture"}>
    {mostrarFormulario && 
    	<Formulario />
    }
    {mostrarTokens && 
    	<TokensList />
    }
    </div>
)

export default connect( mapStateToProps )(App)

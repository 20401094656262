import * as constants from '../actions/constants';

let initial = {
    formulario:{
        title:"Esperando..",
        name:"waiting",
        bgColor:"#ccc",
        valid: false,
        fields:[],
        installments: []
    },
    stamps:{},
    id: null,
    status: "wait",
    error: null
}

const updatePlanesYCuotas = (formulario) => {
    let indexPlanes = formulario.fields.findIndex((element)=>{return ("v_plan"==element.name)});
    let indexCuotas = formulario.fields.findIndex((element)=>{return ("v_cuotas"==element.name)});
    let installments = formulario.installments ? formulario.installments : [];
    if(indexPlanes >= 0){
        let planes = [{"value":"0", "label":"Seleccionar"}];
        installments.forEach((element)=>{
            let itm = planes.find((ele)=>{return ele.value == element.type.id});
            if(!itm)
                planes.push({"value":element.type.id, "label":element.type.label});
        })
        formulario.fields[indexPlanes].options = planes;
    }
    if(indexCuotas >= 0){
        let cuotas = [{"value":"0", "label":"Seleccionar"}];
        let plan;
        if(indexPlanes){
            if(formulario.fields[indexPlanes].value && formulario.fields[indexPlanes].value != "0")
                plan = formulario.fields[indexPlanes].value;
            else if(formulario.fields[indexPlanes].options.length > 1)
                plan = formulario.fields[indexPlanes].options[1].value;
            if(plan){
                installments.forEach((element)=>{
                    let itm = cuotas.find((ele)=>{return element.type.id == plan });
                    if(itm)
                        cuotas.push({"value":element.share.id, "label":element.share.label});
                })
            }
        }
        formulario.fields[indexCuotas].options = cuotas;
    }
    return formulario;
}

const updateStatusAndValue = (formulario, name, status, value, additionalFields, updateVisual) => {
    // console.log(additionalFields);
    let formCopiado = {...formulario};
    let fields = formulario.fields.slice();
    let index = fields.findIndex((element)=>{return (name==element.name)})
    fields[index] = {...fields[index], status: status, error:null, value:value, updateVisual:updateVisual, additionalFields:{...additionalFields}};
    // if(name=="v_plan"){
    //     let indexCuotas = fields.findIndex((element)=>{return (element.name=="v_cuotas")})
    //     fields[indexCuotas].options[1].label= (fields[index].value=="001") ? "Sin cuotas" : "Pago en 1 cuota";
    // }
    formCopiado.fields = fields;
    formCopiado = updatePlanesYCuotas(formCopiado);
    return formCopiado;
}

const updateStatus = (formulario, name, status, error = null, additionalFields, enableFields, disableFields) => {
    let formCopiado = {...formulario};
    let fields = formulario.fields.slice();
    let index = fields.findIndex((element)=>{return (name==element.name)})
    fields[index] = {...fields[index], status: status, error:error, additionalFields:{...additionalFields}};
    if(enableFields){
        for (let i = 0; i < enableFields.length; i++) {
            let fename = enableFields[i];
            index = fields.findIndex((element)=>{return (fename==element.name)})
            if(index >= 0)
                fields[index].enabled = true;
        }
    }
    if(disableFields){
        for (let i = 0; i < disableFields.length; i++) {
            let fename = disableFields[i];
            index = fields.findIndex((element)=>{return (fename==element.name)})
            if(index >= 0)
                fields[index].enabled = false;
        }
    }
    formCopiado.fields = fields;
    if(name == "v_pan" && additionalFields)
        formCopiado.installments = additionalFields.installments ? additionalFields.installments : [];
    formCopiado = updatePlanesYCuotas(formCopiado);
    return formCopiado;
}

const actualizarEstampa = (stamps, action) => {
    let sts = stamps ? {...stamps} : {}
    let updated = false
    if(sts[action.name]){
        if(sts[action.name] < action.timestamp){
            sts[action.name] = action.timestamp;
            updated = true;
        }
    }else{
        sts[action.name] = action.timestamp;
        updated = true;
    }
    return {stamps:sts, updated:updated}
}

const formulario = (state = initial, action) => {
    let stamped;
    // console.log(action);
    switch (action.type) {
        case constants.INICIA_CARGAR_FORMULARIO:
            return {
                ...state,
                id: action.id,
                keyForm: action.keyForm,
                urlServicioFormulario: action.urlServicioFormulario,
                urlServicioTokenize: action.urlServicioTokenize,
                urlServicioAuthorize: action.urlServicioAuthorize,
                urlServicioAffiliation: action.urlServicioAffiliation,
                callback: action.callback,
                initPayCallback: action.initPayCallback,
                errorOnPayCallback: action.errorOnPayCallback,
                additionalFields: action.additionalFields,
                payload: action.payload,
                status: action.type
            };
        case constants.EXITO_CARGAR_FORMULARIO:
            return {
                ...state,
                formulario: action.formulario,
                status: action.type
            };
        case constants.ERROR_CARGAR_FORMULARIO:
            return {
                ...state,
                error: action.error,
                status: action.type
            };

        case constants.INICIA_ACTUALIZAR_VARIABLE:
            return {
                ...state,
                formulario: updateStatusAndValue(state.formulario, action.name, action.type, action.value, action.additionalFields, action.updateVisual)
            };
        
        case constants.EXITO_ACTUALIZAR_VARIABLE: 
            // console.log(action);
            stamped = actualizarEstampa(state.stamps, action);
            // console.log(stamped);
            if(stamped.updated){
                return {
                    ...state,
                    formulario: updateStatus(state.formulario, action.name, action.type, null, action.additionalFields, action.enableFields, action.disableFields),
                    stamps: stamped.stamps
                };
            }
            return {...state}
                
        case constants.ERROR_ACTUALIZAR_VARIABLE:
            // console.log(action);
            stamped = actualizarEstampa(state.stamps, action);
            // console.log(stamped);
            if(stamped.updated){
                return {
                    ...state,
                    formulario: updateStatus(state.formulario, action.name, action.type, action.error, action.additionalFields, action.enableFields, action.disableFields),
                    stamps: stamped.stamps
                };
            }
            return {...state}
                
        case constants.VALIDACION_CORRECTA_FORMULARIO:
            return {
                ...state,
                formulario: { ...state.formulario, valid: true }
            };

        case constants.VALIDACION_FALLIDA_FORMULARIO:
            return {
                ...state,
                formulario: { ...state.formulario, valid: false }
            };

        case constants.INICIA_SOLICITAR_TOKEN:
            return {
                ...state,
                status: action.type
            };

        case constants.EXITO_SOLICITAR_TOKEN:
            return {
                ...state,
                status: action.type
            };

        case constants.ERROR_SOLICITAR_TOKEN:
            return {
                ...state,
                status: action.type
            };


        default:
            return {
                ...state
            };
    }
  }
  
  export default formulario;

export const INICIA_CARGAR_FORMULARIO = "INICIA_CARGAR_FORMULARIO"
export const EXITO_CARGAR_FORMULARIO = "EXITO_CARGAR_FORMULARIO"
export const ERROR_CARGAR_FORMULARIO = "ERROR_CARGAR_FORMULARIO"

export const VALIDACION_CORRECTA_FORMULARIO = "VALIDACION_CORRECTA_FORMULARIO"
export const VALIDACION_FALLIDA_FORMULARIO = "VALIDACION_FALLIDA_FORMULARIO"

export const INICIA_SOLICITAR_TOKEN = "INICIA_SOLICITAR_TOKEN"
export const EXITO_SOLICITAR_TOKEN = "EXITO_SOLICITAR_TOKEN"
export const ERROR_SOLICITAR_TOKEN = "ERROR_SOLICITAR_TOKEN"

export const INICIA_ACTUALIZAR_VARIABLE = "INICIA_ACTUALIZAR_VARIABLE"
export const EXITO_ACTUALIZAR_VARIABLE = "EXITO_ACTUALIZAR_VARIABLE"
export const ERROR_ACTUALIZAR_VARIABLE = "ERROR_ACTUALIZAR_VARIABLE"

export const INICIA_LISTAR_TOKENS = "INICIA_LISTAR_TOKENS"

export const ELEGIR_TOKEN = "ELEGIR_TOKEN"

export const INICIA_AUTORIZAR_CON_TOKEN = "INICIA_AUTORIZAR_CON_TOKEN"
export const EXITO_AUTORIZAR_CON_TOKEN = "EXITO_AUTORIZAR_CON_TOKEN"
export const ERROR_AUTORIZAR_CON_TOKEN = "ERROR_AUTORIZAR_CON_TOKEN"
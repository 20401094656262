import * as constants from '../actions/constants';

let initial = {
    status: "wait",
    tokens: [],
    autorizacion: {
        status: "wait",
    }
}

const tokens = (state = initial, action) => {
    // console.log(action);
    switch (action.type) {

        case constants.EXITO_SOLICITAR_TOKEN:
            return {
                ...state,
                status: action.type,
                tokens: action.tokens
            };

        case constants.INICIA_LISTAR_TOKENS:
            return {
                ...state,
                status: action.type,
                tokens: action.tokens,
                keyForm: action.keyForm,
                urlServicioAuthorize: action.urlServicioAuthorize,
                payload: action.payload,
                callback: action.callback
            };

        case constants.ELEGIR_TOKEN:
            return {
                ...state,
                tokenElegido: action.token
            };

        case constants.INICIA_AUTORIZAR_CON_TOKEN:
            return {
                ...state,
                autorizacion: {
                    status: action.type,
                    token: {...state.tokenElegido}
                }
            };

        case constants.EXITO_AUTORIZAR_CON_TOKEN:
            return {
                ...state,
                autorizacion: {
                    ...state.autorizacion,
                    status: action.type,
                    response: action.response
                }
            };

        case constants.ERROR_AUTORIZAR_CON_TOKEN:
            return {
                ...state,
                autorizacion: {
                    ...state.autorizacion,
                    status: action.type
                }
            };

        default:
            return {
                ...state
            };
    }
  }
  
  export default tokens;

import { combineReducers } from 'redux'
import formulario from './formulario'
import variables from './variables'
import tokens from './tokens'

export default combineReducers({
  formulario,
  variables,
  tokens
})
